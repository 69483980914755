<template>
  <div style="width: 98%">
    <el-table
      :data="props.users"
      style="width: 100%"
      size="small"
      :cell-class-name="cellGrayRoom"
    >
      <el-table-column prop="guestName" label="Room" />
      <el-table-column label="CreateTime">
        <template #default="scope">
          <span
            v-formatter="{
              data: scope.row.createTime,
              formatter: 'HH:mm:ss',
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column prop="agentNickNameList" label="Agent">
        <template #default="scope">
          <span v-if="scope.row.agentNickNameList.length < 1">-</span>
          <span v-else>
            {{ scope.row.agentNickNameList.join() }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" />
      <el-table-column prop="country" label="Country" />
      <el-table-column prop="province" label="province" />
      <el-table-column prop="guestIp" label="Ip" />
    </el-table>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { cellGrayRoom } from '@/utils/style'

const props = defineProps({
  users: {
    type: Object,
    default: () => {
      return [];
    },
  },
})
</script>
