import https from "@/utils/https";
import { Method } from "axios-mapper";

export const getRealTimeStats = () => {
  return https().request("/stats/realTime", Method.GET);
};

export const getRoomStats = () => {
  return https().request("/stats/room", Method.GET);
};
