<template>
  <div>
    <el-form
      ref="settingForm"
      :model="form"
      :rules="formRules"
      label-position="left"
      label-width="150px"
    >
      <el-form-item label="State" prop="state">
        <el-switch
          v-model="form.state"
          active-text="Open"
          inactive-text="Close"
        />
      </el-form-item>
      <el-form-item label="Intro Message" prop="intro">
        <div id="app">
          <!-- replace the api key -->
          <editor
            api-key="arlf5h2wcrqydmiiogww80cfx98khxd8o81s2i93qhq0n7wv"
            :init="{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'formatselect | bold italic underline backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | link | removeformat | code | undo redo |  help',
            }"
            v-model="form.intro"
          />
        </div>
      </el-form-item>
      <el-form-item label="Question Types" prop="questionTypes">
        <el-tag
          v-for="tag in form.questionTypes"
          :key="tag"
          :closable="hasPermission(['sys:pre-chat-setting:update'])"
          :disable-transitions="false"
          @close="handleClose(tag)"
          style="margin-right:5px"
        >
          {{ tag }}
        </el-tag>
        <el-input
          v-if="uiControl.inputVisible"
          ref="saveTagInput"
          v-model="inputValue"
          @keyup.enter="handleInputConfirm"
          @blur="handleInputConfirm"
        />
        <el-button v-else-if="hasPermission(['sys:pre-chat-setting:update'])" class="button-new-tag" size="small" @click="showInput">+ Add Question</el-button>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="initSetting">Reset</el-button>
      <el-button @click="submit" type="primary">
        Confirm
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { required } from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import {
  getSitePreChatSurveySetting,
  updateSitePreChatSurveySetting
} from '../../../api/site-pre-chat-survey'
import { hasPermission } from '../../../utils/util'
import Editor from "@tinymce/tinymce-vue";

const uiControl = reactive({
  inputVisible: false
})
const inputValue = ref('')
const saveTagInput = ref(null)
const form = reactive({
  id: null,
  intro: null,
  state: null,
  questionTypes: []
})
const formRules = reactive({
  id: [required('id is require')],
  intro: [required('intro is require')],
  state: [required('state is require')],
})
const settingForm = ref(null)

async function initSetting() {
  const { data: ret } = await getSitePreChatSurveySetting()
  for (const key in form) {
    if (Object.keys(ret).find(k => k === key)) {
      form[key] = ret[key]
    }
  }
  uiControl.inputVisible = false;
}

function showInput() {
  uiControl.inputVisible = true;
}

function handleClose(questionType) {
  form.questionTypes.splice(form.questionTypes.indexOf(questionType), 1)
}

function handleInputConfirm() {
  if (inputValue.value) {
    form.questionTypes.push(inputValue.value)
  }
  uiControl.inputVisible = false;
  inputValue.value = '';
}

function submit() {
  settingForm.value.validate(async valid => {
    if (valid) {
      await updateSitePreChatSurveySetting(form)
      await initSetting()
      ElMessage({ message: 'Update Successful', type: 'success' })
    }
  })
}

onMounted(async () => {
  await initSetting()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
