<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-input
          v-model="siteRequest.siteName"
          size="small"
          style="width: 200px"
          placeholder="Site Name"
        />
        <el-input
          v-model="siteRequest.siteCode"
          size="small"
          style="width: 200px; margin-left: 20px"
          placeholder="Site Code"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadSites"
        >
          Search
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          reset
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="580px"
    >
      <el-form
        ref="siteFormRef"
        :model="siteForm"
        :rules="siteFormRules"
        :inline="true"
        size="small"
        label-width="150px"
        v-if="
          uiControl.dialogType === 'CREATESITE' ||
            uiControl.dialogType === 'EDITSITE'
        "
      >
        <el-form-item label="Site Name" prop="siteName">
          <el-input v-model="siteForm.siteName" style="width: 350px" />
        </el-form-item>
        <el-form-item label="Site Code" prop="siteCode">
          <el-input v-model="siteForm.siteCode" style="width: 350px" />
        </el-form-item>
        <el-form-item label="IM Account" prop="imAccount">
          <el-input v-model="siteForm.imAccount" style="width: 350px" />
        </el-form-item>
        <el-form-item label="IM Password" prop="imPassword">
          <el-input v-model="siteForm.imPassword" style="width: 350px" />
        </el-form-item>
        <el-form-item label="Partner ID" prop="partnerId">
          <el-input v-model="siteForm.partnerId" style="width: 350px" />
        </el-form-item>
        <el-form-item label="Remark" prop="remark">
          <el-input
            v-model="siteForm.remark"
            type="textarea"
            style="width: 350px"
            :rows="5"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
      <el-form
        ref="domainFormRef"
        :model="domainForm"
        :rules="domainFormRules"
        :inline="true"
        size="small"
        label-width="150px"
        v-if="uiControl.dialogType === 'CREATEDOMAIN'"
      >
        <el-form-item label="Site Name" prop="siteId">
          <el-select
            disabled
            v-model="domainForm.siteId"
            value-key="id"
            placeholder="Please choose"
            style="width: 350px"
            filterable
            @focus="loadSiteNames"
          >
            <el-option
              v-for="item in sites.list"
              :key="item.id"
              :label="item.siteName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-card shadow="never">
      <div class="card-header">
        <span>Site List</span>
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="showDialog('CREATESITE')"
        >
          Add
        </el-button>
      </div>
      <el-divider />
      <div>
        <el-table
          :data="list"
          v-loading="sitePage.loading"
          ref="treeTable"
          row-key="id"
          size="small"
          highlight-current-row
          lazy
          :key="tableKey"
          :load="loadSearch"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          :cell-class-name="cellGrayWhite"
        >
          <el-table-column prop="siteName" label="Site Name" />
          <el-table-column prop="siteCode" label="Site Code" />
          <el-table-column prop="imAccount" label="IM Account" />
          <el-table-column prop="imPassword" label="IM Password" width="350" />
          <el-table-column prop="createTime" label="Created Time" width="150" />
          <el-table-column label="operate" align="right" fixed="right" width="100">
            <template #default="scope">
              <el-button
                icon="el-icon-edit"
                size="mini"
                type="success"
                @click="showEdit(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, ref } from 'vue'
import {
  required,
  size,
  isAlphaNumericUnderscore,
} from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import {
  createSite,
  getSites,
  updateSite,
  getSiteListSimple,
} from '../../../api/site'
import { cellGrayWhite } from '@/utils/style'

const siteFormRef = ref(null)
const treeTable = ref(null)
const tableKey = ref(Math.random())
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: '',
  editable: '',
})
const list = reactive([])
const sitePage = reactive({
  loading: false,
  currentRow: [],
})

const siteRequest = reactive({
  size: 30,
  current: 1,
  siteName: null,
  siteCode: null,
})
const siteForm = reactive({
  id: null,
  siteName: null,
  siteCode: null,
  imAccount: null,
  imPassword: null,
  partnerId: null,
  remark: null,
})

const sites = reactive({
  list: [],
})
const validateAlphaNumericUnderscore = (rule, value, callback) => {
  if (!isAlphaNumericUnderscore(value)) {
    callback(new Error('Please enter string and number only'))
  } else {
    callback()
  }
}
const siteFormRules = reactive({
  siteName: [
    required('Site Name is require'),
    size(4, 20, 'Please enter at least 6 characters.'),
  ],
  siteCode: [
    required('Site Code is require'),
    size(4, 20, ' Please enter at least 4 characters.'),
    { validator: validateAlphaNumericUnderscore, trigger: 'blur' },
  ],
  imAccount: [required('IM Account is require')],
  imPassword: [
    required('IM Password is require'),
    size(6, 20, 'Please enter at least 6 characters.'),
  ],
  partnerId: [required('partner Id is require')],
})

function resetQuery() {
  siteRequest.siteName = null
  siteRequest.siteCode = null
}

async function loadSites() {
  sitePage.loading = true
  siteRequest.parentId = null
  const requestCopy = { ...siteRequest }
  const query = {}
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value
    }
  })
  const { data: ret } = await getSites(query)
  list.length = 0
  list.push(...ret)
  tableKey.value = Math.random()
  sitePage.loading = false
}

async function loadSearch(tree, treeNode, resolve) {
  siteRequest.parentId = tree.id
  const { data: children } = await getSites(siteRequest)
  resolve(children)
}

async function loadSiteNames() {
  const { data: ret } = await getSiteListSimple()
  sites.list = ret
}

function showDialog(type) {
  if (type === 'CREATESITE') {
    if (siteFormRef.value) {
      siteFormRef.value.resetFields()
    }
    uiControl.dialogTitle = 'Add Site'
    siteForm.id = null
  } else if (type === 'EDITSITE') {
    uiControl.dialogTitle = 'Edit Site'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function showEdit(site) {
  showDialog('EDITSITE')
  nextTick(() => {
    for (const key in site) {
      if (Object.keys(siteForm).find(k => k === key)) {
        siteForm[key] = site[key]
      }
    }
  })
}

function createSiteFunc() {
  siteFormRef.value.validate(async valid => {
    if (valid) {
      await createSite(siteForm)
      uiControl.dialogVisible = false
      await loadSites()
      ElMessage({ message: 'Add Success', type: 'success' })
    }
  })
}

function editSiteFunc() {
  siteFormRef.value.validate(async valid => {
    if (valid) {
      await updateSite(siteForm)
      uiControl.dialogVisible = false
      await loadSites()
      ElMessage({ message: 'Edit Success', type: 'success' })
    }
  })
}

function submit() {
  if (uiControl.dialogType === 'CREATESITE') {
    createSiteFunc()
  } else if (uiControl.dialogType === 'EDITSITE') {
    editSiteFunc()
  }
}

onMounted(() => {
  loadSites()
  loadSiteNames()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
