<template>
  <div class="menu-main">
    <div class="header-container">
      <div class="btn-group" v-permission="['sys:api:button:create']">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="showDialog('CREATE')"
        >
          Add
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="650px"
      @close="uiControl.popoverVisible = false"
      destroy-on-close
    >
      <el-form
        ref="menuForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="130px"
        style="margin: 0px 10px 0px 10px"
      >
        <el-form-item label="Site" prop="siteId">
          <el-select
            v-model="form.siteId"
            size="small"
            placeholder="Site"
            style="width: 394px"
            :disabled="true"
          >
            <el-option
              v-for="item in userSites"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Button Type" prop="type">
          <el-radio-group v-model="form.type" size="mini" style="width: 500px" @change="changeType">
            <el-radio-button
              v-for="item in getButtonType()"
              :key="item.value"
              :value="item.value"
              :label="item.value"
            >{{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Name" prop="name">
          <el-input
            v-model="form.name"
            placeholder="Button name"
            style="width: 354px"
          />
        </el-form-item>

        <el-form-item
          v-show="form.type.toString() === ButtonType.API || form.type.toString() === ButtonType.LOGIN_PASSWORD"
          label="Api"
          prop="apiId"
        >
          <el-select
            v-model="form.apiId"
            size="small"
            placeholder="Api"
            style="width: 394px"
          >
            <el-option
              v-for="item in getApiList()"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Status" prop="status">
          <el-radio-group
            v-model="form.status"
            size="mini"
            style="width: 300px"
          >
            <el-radio-button label="OPEN">Open</el-radio-button>
            <el-radio-button label="CLOSE">Close</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="resetForm">Cancel</el-button>
        <el-button size="small" @click="submit" type="primary">
          Confirm
        </el-button>
      </div>
    </el-dialog>

    <!-- eslint-disable -->
    <el-table
      :data="data.list"
      style="width: 100%"
      size="small"
      row-key="id"
      ref="treeTable"
      lazy
      :load="load"
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column prop="name" label="Button Name" align="left"/>
      <el-table-column prop="status" label="Status" align="left">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 'OPEN'" type="success">
            {{ scope.row.status }}
          </el-tag>
          <el-tag v-if="scope.row.status === 'CLOSE'" type="danger">
            {{ scope.row.status }}
          </el-tag>
          <el-tag v-if="scope.row.status === 'TEST'">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Type" align="left">
        <template #default="scope">
          <el-tag type="info">
            {{ scope.row.type }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="operate" align="center">
        <template #default="scope">
          <el-button v-permission="['sys:api:button:update']"
                     icon="el-icon-edit"
                     size="mini"
                     type="success"
                     @click="editMenu(scope.row)"
          />
          <el-button v-permission="['sys:api:button:delete']"
                     icon="el-icon-remove"
                     size="mini"
                     type="danger"
                     @click="removeMenu(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { required } from '../../../utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  fetchButton,
  createSiteApiButton,
  updateSiteApiButton,
  deleteSiteApiButton, ButtonType,
} from '../../../api/site-api-btn'
import { getSiteApiSimple } from '../../../api/site-api'
import { store } from '../../../store'
import { cellGrayWhite } from '@/utils/style'

const siteId = computed(() => {
  return store.state.user.siteId
})

const userSites = computed(() => {
  return store.state.user.sites
})

const uiControl = reactive({
  dialogVisible: false,
  popoverVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
})
const data = reactive({
  list: [],
})
const form = reactive({
  id: null,
  name: '',
  siteId: null,
  type: ButtonType.API,
  apiId: null,
  status: null,
})
const formRules = reactive({
  name: [required('name is require')],
  siteId: [required('site is require')],
  type: [required('type is require')],
})
const menuForm = ref(null)
const tableKey = ref(Math.random())

const apis = reactive({
  list: [],
})

const request = reactive({
  siteId: null,
})

function changeType(value) {
  if (value !== ButtonType.API && value !== ButtonType.LOGIN_PASSWORD) {
    form.apiId = null;
  }
}

function getButtonType() {
  const types = [];
  Object.keys(ButtonType).forEach(function (key) {
    types.push({ value: ButtonType[key], label: key })
  });
  return types;
}

function getApiList() {
  if (form.type === ButtonType.API) {
    return apis.list;
  } else if (form.type === ButtonType.LOGIN_PASSWORD) {
    return apis.list.filter(value => value.code === "login_with_password")
  }
}

async function loadApi() {
  const { data: api } = await getSiteApiSimple(form.siteId)
  apis.list = api
}

async function initList() {
  data.list = []
  const { data: menus } = await fetchButton(request)
  JSON.parse(JSON.stringify(menus)).map(item => {
    data.list.push(item)
  })
}

async function load(tree, treeNode, resolve) {
  resolve(tree.children)
}

function showDialog(type) {
  if (type === 'CREATE') {
    form.name = ''
    form.parentId = -1
    form.siteId = siteId.value
    form.status = 'OPEN'
    form.type = ButtonType.API
    uiControl.dialogTitle = 'Add Button'
  } else {
    uiControl.dialogTitle = 'Edit Button'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

/**
 * 刷新菜单table
 * @param menu
 */
function refresh() {
  request.parentId = null
  initList()
  tableKey.value = Math.random()
}

/**
 * 更新菜单
 * @param menu
 */
function editMenu(menu) {
  for (const key in menu) {
    if (Object.keys(form).find(k => k === key)) {
      form[key] = menu[key]
    }
  }
  showDialog('EDIT')
}

/**
 *  新增菜单
 */
function create() {
  menuForm.value.validate(async valid => {
    if (valid) {
      await createSiteApiButton(form)
      refresh(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Create Successful', type: 'success' })
    }
  })
}

/**
 *  编辑菜单
 */
function edit() {
  menuForm.value.validate(async valid => {
    if (valid) {
      debugger
      await updateSiteApiButton(form)
      refresh(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Successful', type: 'success' })
    }
  })
}

function submit() {
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else {
    edit()
  }
}

/**
 * 删除菜单
 * @param menu
 */
function removeMenu(menu) {
  ElMessageBox.confirm('This menu has child，Please delete child first', {
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    type: 'warning',
  }).then(async () => {
    await deleteSiteApiButton(menu.id)
    refresh(menu)
    ElMessage({ message: 'Delete Successful', type: 'success' })
  })
}

function resetForm() {
  uiControl.dialogVisible = false
}

onMounted(async () => {
  await loadApi()
  await initList()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin: 0px 45px 0px 0px;
}
</style>
<style rel="stylesheet/scss">
.tox-tinymce-aux {
  z-index: 9999 !important;
}

.tox-notifications-container {
  display: none;
}
</style>
