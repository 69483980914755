import Cache from 'cache'

class Cacher {
  constructor() {
    this.cache = new Cache(10 * 1000);
  }

  async getOrFetch(key, getFunc, ttl = 60 * 1000) {
    const data = this.cache.get(key)
    if (!data) {
      const data = await getFunc()
      this.cache.put(key, data, ttl);
    }
    return this.cache.get(key)
  }

  get(key) {
    return this.cache.get(key)
  }

  set(key, data, ttl = 60 * 1000) {
    this.cache.put(key, data, ttl);
  }
}

export const cacher = new Cacher();
