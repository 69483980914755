import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getSiteApi = (query) => {
  return https().request("/site-api", Method.GET, query, ContentType.form);
};

export const createSiteApi = (data) => {
  return https().request("/site-api", Method.POST, data, ContentType.form);
};

export const updateSiteApi = (data) => {
  return https().request(`/site-api/${data.id}?_method=PUT`, Method.POST, data, ContentType.form);
};

export const deleteSiteApi = (siteApiId) => {
  return https().request(`/site-api/${siteApiId}?_method=DELETE`, Method.POST);
};

export const getSiteApiSimple = (request) => {
  return https().request("/site-api/simple", Method.GET, request);
};
