<template>
  <div style="width: 90%">
    <el-table
      :data="props.users"
      style="width: 100%"
      size="small"
      :cell-class-name="cellGrayWhiteOnline"
    >
      <el-table-column prop="loginName" label="LoginName" />
      <el-table-column prop="nickName" label="NickName" />
      <el-table-column label="Ongoing Chats" width="150px">
        <template #default="scope">
          <span>{{ scope.row.currentDialog }} / {{ scope.row.dialogMax }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" />
      <el-table-column label="Online Time">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.onlineSeconds) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Away Time">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.awaySeconds) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Offline Time">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.offlineSeconds) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { showDateTime } from '@/utils/util'
import { cellGrayWhiteOnline } from '@/utils/style'

const props = defineProps({
  users: {
    type: Object,
    default: () => {
      return [];
    },
  },
})
</script>
