<template>
  <div class="menu-main">
    <div class="header-container">
      <div class="btn-group" v-permission="['sys:canned:message:create']">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="showDialog('CREATE')"
        >
          Add
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="660px"
      @close="uiControl.popoverVisible = false"
      destroy-on-close
    >
      <el-form
        ref="menuForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="Site" prop="siteId" v-if="hasRole(['ADMIN'])">
          <el-select
            v-model="form.siteId"
            size="small"
            placeholder="Site"
            style="width: 350px"
            @change="updateSiteId()"
            :disabled="true"
          >
            <el-option
              v-for="item in userSites"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Name" prop="name">
          <el-input v-model="form.name" placeholder="canned message name" />
        </el-form-item>
        <el-form-item label="Message" prop="message">
          <el-input
            v-model="form.message"
            :rows="2"
            type="textarea"
            placeholder="Please input"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item label="Superior Category" prop="parentId">
          <TreeSelect
            style="width: 450px"
            placeholder="please select superior category"
            @selected="selectTreeNode"
            :view-val="form.parentId"
            :siteId="siteId"
            :key="reloadTreeComp"
          />
        </el-form-item>
        <el-form-item label="Order" prop="sequence">
          <el-input-number
            v-model="form.sequence"
            placeholder="Highest Number means the top "
          />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="resetForm">Cancel</el-button>
        <el-button size="small" @click="submit" type="primary">
          Confirm
        </el-button>
      </div>
    </el-dialog>

    <!-- eslint-disable -->
    <el-table
      :data="list"
      style="width: 100%"
      size="small"
      row-key="id"
      ref="treeTable"
      lazy
      :key="tableKey"
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column
        prop="name"
        label="Canned Message Name"
        width="250"
        align="left"
      />
      <el-table-column prop="message" label="Message" />
      <el-table-column prop="sequence" label="Order" width="100" />
      <el-table-column label="operate" align="center" width="200">
        <template #default="scope">
          <el-button
            v-permission="['sys:canned:message:update']"
            icon="el-icon-edit"
            size="mini"
            type="success"
            @click="editMenu(scope.row)"
          />
          <el-button
            v-permission="['sys:canned:message:delete']"
            icon="el-icon-remove"
            size="mini"
            type="danger"
            @click="removeMenu(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import TreeSelect from './tree-select'
import { required } from '../../../utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import { hasRole } from '../../../utils/util'
import {
  createCannedMessage,
  deleteCannedMessage,
  getCannedMessage,
  updateCannedMessage,
} from '../../../api/canned-message'
import { store } from '../../../store'
import { cellGrayWhite } from '@/utils/style'

const userSiteId = computed(() => {
  return store.state.user.siteId
})

const userSites = computed(() => {
  return store.state.user.sites
})

const uiControl = reactive({
  dialogVisible: false,
  popoverVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
})
const list = reactive([])
const form = reactive({
  id: null,
  name: null,
  message: null,
  siteId: null,
  parentId: null,
  sequence: null,
})
const formRules = reactive({
  siteId: [required('site is require')],
  name: [required('menu name is require')],
  message: [required('message is require')],
})
const menuForm = ref(null)
const treeTable = ref(null)
const tableKey = ref(Math.random())

const request = reactive({
  siteId: null,
})
const reloadTreeComp = ref(0)
const siteId = ref(null)

async function initList() {
  const { data: menus } = await getCannedMessage(request)
  list.length = 0
  list.push(...menus)
}

async function load(tree, treeNode, resolve) {
  request.parentId = tree.id
  const { data: children } = await getCannedMessage(request)
  resolve(children)
}

function selectTreeNode(id) {
  form.parentId = id
}

function showDialog(type) {
  if (type === 'CREATE') {
    Object.keys(form).forEach(key => {
      if (key === 'hidden') {
        form.hidden = false
      } else {
        form[key] = null
      }
    })
    form.siteId = userSiteId.value
    siteId.value = userSiteId.value
    uiControl.dialogTitle = 'Add Canned Message'
  } else {
    uiControl.dialogTitle = 'Edit Canned Message'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

async function updateSiteId() {
  siteId.value = form.siteId
  reloadTree()
}

function reloadTree() {
  reloadTreeComp.value++
}
/**
 * 刷新菜单table
 * @param menu
 */
function refresh() {
  request.parentId = null
  initList()
  tableKey.value = Math.random()
}

/**
 * 更新菜单
 * @param menu
 */
function editMenu(menu) {
  for (const key in menu) {
    if (Object.keys(form).find(k => k === key)) {
      form[key] = menu[key]
    }
  }
  updateSiteId()
  showDialog('EDIT')
}

/**
 *  新增菜单
 */
function create() {
  menuForm.value.validate(async valid => {
    if (valid) {
      await createCannedMessage(form)
      refresh(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Create Successful', type: 'success' })
    }
  })
}

/**
 *  编辑菜单
 */
function edit() {
  menuForm.value.validate(async valid => {
    if (valid) {
      await updateCannedMessage(form)
      refresh(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Successful', type: 'success' })
    }
  })
}

function submit() {
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else {
    edit()
  }
}

/**
 * 删除菜单
 * @param menu
 */
function removeMenu(menu) {
  if (menu.hasChildren) {
    ElMessage({
      message: 'This menu has child，Please delete child first',
      type: 'warning',
    })
  } else {
    ElMessageBox.confirm('Confirm the deletion', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warning',
    }).then(async () => {
      await deleteCannedMessage(menu.id)
      refresh(menu)
      ElMessage({ message: 'Delete Successful', type: 'success' })
    })
  }
}

function resetForm() {
  uiControl.dialogVisible = false
}

onMounted(async () => {
  await initList()
  updateSiteId()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
