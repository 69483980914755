<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-input
          v-model="request.name"
          size="small"
          style="width: 200px"
          placeholder="user name"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadUser"
        >
          Search
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          reset
        </el-button>
      </div>
      <!-- <div class="btn-group">
        <el-button
          icon="el-icon-edit"
          size="mini"
          type="success"
          v-permission="['sys:user:update']"
          @click="showEdit()"
          :disabled="uiControl.editBtn"
        >
          Edit
        </el-button>
      </div> -->
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="580px"
    >
      <el-form
        ref="userForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="User Name" prop="loginName">
          <el-input
            :disabled="uiControl.dialogType !== 'CREATE'"
            v-model="form.loginName"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="Dialog Max"
          prop="dialogMax"
          v-if="uiControl.dialogType === 'CHANGE_DIALOGMAX'"
        >
          <el-input-number
            type="number"
            v-model.number="form.dialogMax"
            :min="0"
            style="width: 350px"
            controls-position="right"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="mini"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column label="Site" :formatter="toSiteName" />
      <el-table-column prop="loginName" label="User Name">
        <template #default="scope">
          {{ scope.row.loginName }}
          <el-tag
            size="small"
            v-if="scope.row.loginName === LOGIN_USER_NAME"
            style="margin-left: 5px"
          >
            it's you
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="Nick Name" />
      <el-table-column prop="status" label="State">
        <template #default="scope">
          <el-switch
            :disabled="true"
            v-model="scope.row.status"
            active-color="#409EFF"
            inactive-color="#F56C6C"
          />
        </template>
      </el-table-column>
      <el-table-column prop="dialogMax" label="Dialog Max" />
      <el-table-column
        label="operate"
        width="200"
        align="right"
        v-if="hasPermission(['sys:user:update:auto-allocation'])"
      >
        <template #default="scope">
          <el-button
            size="mini"
            type="success"
            icon="el-icon-chat-dot-round"
            v-permission="['sys:user:update:auto-allocation']"
            @click="showChangeDialogmax(scope.row)"
          >
            Update DialogMax
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import { required } from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import { getUsers, updateUserDialogMax } from '../../../api/user'
import { useStore } from '../../../store'
import { hasPermission } from '../../../utils/util'
import { cellGrayWhite } from '@/utils/style'
import { getSiteListSimple } from '../../../api/site'

const store = useStore()
const LOGIN_USER_NAME = computed(() => store.state.user.name)
const siteList = reactive({
  list: [],
})
const userSiteId = computed(() => {
  return store.state.user.siteId
})

const userForm = ref(null)
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'EDIT',
  // editBtn: true,
})
const page = reactive({
  pages: 0,
  records: [],
})
const request = reactive({
  size: 30,
  current: 1,
  name: null,
  enable: null,
  siteIds: ',' + userSiteId.value + ',',
})

const form = reactive({
  id: null,
  loginName: null,
  dialogMax: 0,
})

const formRules = reactive({
  dialogMax: [required('Dialog Max is require')],
})

function resetQuery() {
  request.name = null
  request.enable = null
}

async function loadUser() {
  const { data: ret } = await getUsers(request)
  page.pages = ret.pages
  page.records = ret.records
}

function changePage(page) {
  request.current = page
  loadUser()
}

function showDialog(type) {
  if (type === 'EDIT') {
    uiControl.dialogTitle = 'Edit User'
  } else {
    uiControl.dialogTitle = 'Update DialogMax'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function showChangeDialogmax(user) {
  showDialog('CHANGE_DIALOGMAX')
  nextTick(() => {
    form.id = user.id
    form.loginName = user.loginName
    form.dialogMax = user.dialogMax
  })
}
function updateDialogMax() {
  userForm.value.validate(async valid => {
    if (valid) {
      await updateUserDialogMax(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Dialog Max Success', type: 'success' })
    }
    loadUser()
  })
}

function submit() {
  updateDialogMax()
}

function toSiteName(row, column, cellValue, index) {
  let siteIdArr = []
  const siteStrList = []
  if (row.siteIds) {
    siteIdArr = row.siteIds.split(',')
    siteIdArr.forEach(element => {
      if (element !== '') {
        siteStrList.push(
          siteList.list.find(site => site.id === Number(element)).siteName
        )
      }
    })
    return siteStrList.join(' , ')
  } else {
    return '-'
  }
}

async function loadSites() {
  const { data: ret } = await getSiteListSimple()
  siteList.list = ret
}

onMounted(() => {
  loadSites()
  loadUser()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}
</style>
