<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-date-picker
          v-model="recordTime"
          type="date"
          placeholder="Record Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="showUserOnlineStatistics()"
        >
          Search
        </el-button>
      </div>
    </div>
    <el-table
      :data="list.records"
      ref="table"
      row-key="userId"
      @expand-change="exChange"
      size="small"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column type="expand">
        <template #default="scope">
          <el-scrollbar v-if="scope.row.timelines" height="400px">
            <el-timeline style="margin-top:10px">
              <el-timeline-item
                v-for="(timeline, index) in scope.row.timelines"
                :key="index"
                :timestamp="timeline.time"
                :color="getTimelineIconColor(timeline)"
              >
                {{ timeline.onlineStatus }}
              </el-timeline-item>
            </el-timeline>
          </el-scrollbar>
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="Nick name" />
      <el-table-column label="Online">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.onlineSeconds) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Away">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.awaySeconds) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Offline">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.offlineSeconds) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Chart" width="400px">
        <template #default="scope">
          <div class="chart-div">
            <Chart
              :options="processPieChart(scope.row)"
              :name="scope.row.nickName"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import { getUserOnlineStatistics, getUserOnlineTimeline } from '../../../api/report'
import { showDateTime, convertDate } from '@/utils/util'
import Chart from '@/components/charts/Charts'
import { cellGrayWhite } from '@/utils/style'

const offlineColor = '#8B8D91';
const onlineColor = '#77D673';
const awayColor = '#F5BD40';

const list = reactive({
  records: [],
  timelines: [],
})

const recordTime = ref(convertDate(new Date()))

function processPieChart(scopeData) {
  const pieData = []

  pieData.push({ value: scopeData.onlineSeconds, name: 'Online' })
  pieData.push({ value: scopeData.offlineSeconds, name: 'Offline' })
  pieData.push({ value: scopeData.awaySeconds, name: 'Away' })
  return {
    tooltip: {
      show: false,
    },
    legend: {
      orient: 'vertical',
      right: 5,
      itemWidth: 7,
      itemHeight: 7,
      fontSize: 4,
    },
    color: [onlineColor, offlineColor, awayColor],
    series: [
      {
        type: 'pie',
        radius: ['5%', '100%'],
        center: ['20%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 1,
          borderColor: '#fff',
          borderWidth: 1,
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: pieData,
      },
    ],
  }
}

function getTimelineIconColor(timeline) {
  if (timeline.onlineStatus === 'OFFLINE') {
    return offlineColor;
  } else if (timeline.onlineStatus === 'AWAY') {
    return awayColor;
  } else {
    return onlineColor;
  }
}

async function showUserOnlineStatistics() {
  const { data: res } = await getUserOnlineStatistics(recordTime.value)
  list.records = res
}

async function exChange(row) {
  if (!row.timelines) {
    const { data: res } = await getUserOnlineTimeline(recordTime.value, row.userId)
    if (res.length) {
      row.timelines = res;
    }
  }
}

onMounted(async () => {
  await showUserOnlineStatistics()
})
</script>

<style scoped>
.chart-div {
  width: 180px;
  height: 70px;
}

.search {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
</style>
