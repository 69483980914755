import { ContentType, Method } from "axios-mapper";
import https from "../utils/https";

// export const MenuType = {
//   CATALOG: "CATALOG", MENU: "MENU", BUTTON: "BUTTON"
// }

// export const MenuViewScope = {
//   ALL: "ALL", ADMIN_ONLY: "ADMIN_ONLY"
// }

const filterNull = (form) => {
  const data = {};
  Object.entries(form).forEach(([key, value]) => {
    if (value !== null && value !== "") {
      data[key] = value;
    }
  });
  return data;
};

// export const fetchWrapUp = (request) => {
//   return https().request("/cache/site-wrap-up", Method.GET, request);
// };
export const fetchWrapUp = (request) => {
  return https().request("/cache/site-wrap-up", Method.GET, request);
};

export const getWrapUp = (request) => {
  return https().request("/site-wrap-up", Method.GET, request);
};

export const fetchSimpleWrapUp = (id, siteId) => {
  return https().request("/site-wrap-up/simple", Method.GET, { id: id, siteId: siteId });
};

export const fetchWrapUpName = (parentId) => {
  return https().request(`/site-wrap-up/${parentId}`, Method.GET);
};

export const createWrapUp = (menu) => {
  const data = filterNull(menu);
  return https().request(`/site-wrap-up`, Method.POST, data, ContentType.form);
};

export const updateWrapMenu = (menu) => {
  const data = filterNull(menu);
  return https().request(`/site-wrap-up/${menu.id}?_method=PUT`, Method.POST, data, ContentType.form);
};

export const deleteWrapMenu = (menuId) => {
  return https().request(`/site-wrap-up/${menuId}?_method=DELETE`, Method.POST);
};

export const fetchChildren = (menuId) => {
  return https().request(`/site-wrap-up/${menuId}/child-ids`, Method.GET);
};

export const updateRoomWrapUp = (data) => {
  return https().request(`/site-room/updateWrapUp`, Method.POST, data, ContentType.form);
};
