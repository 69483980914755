import https from "@/utils/https";
import { Method, ContentType } from "axios-mapper";

export const getSitePreChatSurveySetting = () => {
  return https().request("/site-pre-chat-setting", Method.GET);
};

export const updateSitePreChatSurveySetting = (data) => {
  return https().request(`/site-pre-chat-setting?_method=PUT`, Method.POST, data, ContentType.form);
};
