import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const statsType = {
  DAY: "DAY", HOUR: "HOUR"
}

export const getWrapUpStats = (startDate, endDate) => {
  return https().request("/report/wrapUpStats", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getBrowserStats = (startDate, endDate) => {
  return https().request("/report/browserStats", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getDeviceStats = (startDate, endDate) => {
  return https().request("/report/deviceStats", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getFromStats = (startDate, endDate) => {
  return https().request("/report/fromStats", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getLocationStats = (startDate, endDate) => {
  return https().request("/report/locationStats", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getRatingStats = (startDate, endDate) => {
  return https().request("/report/ratingStats", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getUserRatingStats = (startDate, endDate) => {
  return https().request("/report/userRatingStats", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getUserComments = (pageable) => {
  return https().request("/report/userRatingComments", Method.GET, pageable, ContentType.form);
};

export const getUserResponseStats = (query) => {
  return https().request("/report/userResponseStats", Method.GET, query, ContentType.form);
};

export const getUserOnlineStatistics = (recordDate) => {
  return https().request("/report/userOnline", Method.GET, { recordDate: recordDate });
};

export const getUserOnlineTimeline = (recordDate, userId) => {
  return https().request(`/report/userOnline/${userId}/timeline`, Method.GET, { recordDate: recordDate });
};

export const getRoomsStatisticsByTime = (startDate, endDate, statsType) => {
  return https().request("/report/rooms/by-time", Method.GET, { startDate: startDate, endDate: endDate, statisticsType: statsType });
};

export const getRoomsStatisticsByUser = (startDate, endDate) => {
  return https().request("/report/rooms/by-user", Method.GET, { startDate: startDate, endDate: endDate });
};

export const getRealTimeUsersStats = () => {
  return https().request("/report/realTime", Method.GET);
};
