import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getCannedMessage = (query) => {
  return https().request("/site-canned-message", Method.GET, query, ContentType.form);
};

export const createCannedMessage = (data) => {
  return https().request("/site-canned-message", Method.POST, data, ContentType.form);
};

export const updateCannedMessage = (data) => {
  return https().request(`/site-canned-message/${data.id}?_method=PUT`, Method.POST, data, ContentType.form);
};

export const deleteCannedMessage = (ids) => {
  return https().request(`/site-canned-message?_method=DELETE`, Method.POST, { ids: ids }, ContentType.form);
};

export const fetchSimpleCannedMessage = (id, siteId) => {
  return https().request("/site-canned-message/simple", Method.GET, { id: id, siteId: siteId });
};

export const fetchCannedMessageName = (parentId) => {
  return https().request(`/site-canned-message/${parentId}`, Method.GET);
};
