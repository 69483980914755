<template>
  <el-row>
    <el-col :span="12">
      <h4>RealTime ({{ showTime(stats.value.time) }})</h4>
      <statisticsRealtime v-if="stats.value" :stats="stats.value" />
      <h4>Agents ({{ showTime(stats.value.time) }})</h4>
      <realUsers :users="stats.value.users" />
    </el-col>
    <el-col :span="12">
      <h4>Recently Rooms</h4>
      <realRooms :users="stats.value.rooms" />
    </el-col>
  </el-row>
</template>

<script setup>
import statisticsRealtime from '@/views/home/statistics-realtime'
import realUsers from '@/views/report/real-time/real-time-users'
import realRooms from '@/views/report/real-time/real-time-rooms'
import { getRealTimeUsersStats } from '@/api/report'
import { convertDateTime } from '@/utils/util'
import { onMounted, reactive, ref, onBeforeUnmount, onBeforeMount } from '@vue/runtime-core'

const stats = reactive({
  value: {},
})
let timer = ref(null);

async function getStatsRealTime() {
  const { data: statResult } = await getRealTimeUsersStats()
  stats.value = statResult;
}

function showTime(time) {
  return "update time:" + convertDateTime(new Date(time - 60000), 'HH:mm')
}

onMounted(async () => {
  await getStatsRealTime()
})

onBeforeUnmount(async () => {
  clearInterval(timer);
})
onBeforeMount(async () => {
  timer = setInterval(getStatsRealTime, 30000)
})

</script>
