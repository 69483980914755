<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="btn-group">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="showDialog('CREATE')"
          v-permission="['sys:api:create']"
        >
          Add
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="580px"
    >
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="form.name" style="width: 350px" />
        </el-form-item>
        <el-form-item
          label="Code"
          prop="code"
          v-if="
            uiControl.dialogType === 'CREATE' || uiControl.dialogType === 'EDIT'
          "
        >
          <el-select
            clearable
            filterable
            allow-create
            default-first-option
            v-model="form.code"
            size="small"
            placeholder="agent name"
            class="filter-item"
            style="width: 350px"
          >
            <el-option
              v-for="item in builtInApiCode"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-tooltip placement="top">
            <template #content>
              <strong>fetch_user_info</strong> <br>
              <strong>login_with_password</strong> <br>
              <strong>offline_msg_notification</strong> <br>
              are built-in api code
            </template>
            <el-icon style="padding-left: 10px">
              <Warning />
            </el-icon>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="BodyJson"
          prop="bodyJson"
          v-if="
            uiControl.dialogType === 'CREATE' || uiControl.dialogType === 'EDIT'
          "
        >
          <el-input
            v-model="form.bodyJson"
            :rows="2"
            type="textarea"
            placeholder="Please input"
            style="width: 350px"
          />
          <el-tooltip placement="top">
            <template #content>
              <strong>${userToken}</strong> <br>
              <strong>${apiCode}</strong> <br>
              <strong>${siteCode}</strong> <br>
              <strong>${ip}</strong> <br>
              <strong>${itemId}</strong> <br>
              <strong>${loginWay}</strong> <br>
              <strong>${deviceId}</strong> <br>
              are built-in parameters
            </template>
            <el-icon style="padding-left: 10px">
              <Warning />
            </el-icon>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="Url"
          prop="url"
          v-if="
            uiControl.dialogType === 'CREATE' || uiControl.dialogType === 'EDIT'
          "
        >
          <el-input
            v-model="form.url"
            :rows="2"
            type="textarea"
            placeholder="Please input"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item label="Site" prop="siteId" v-if="hasRole(['ADMIN'])">
          <el-select
            v-model="form.siteId"
            size="small"
            placeholder="Site"
            style="width: 350px"
            :disabled="true"
          >
            <el-option
              v-for="site in userSites"
              :key="site.id"
              :label="site.name"
              :value="site.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="Need Login"
          v-if="
            uiControl.dialogType === 'CREATE' || uiControl.dialogType === 'EDIT'
          "
        >
          <el-radio-group v-model="form.needLogin">
            <el-radio-button label="true">YES</el-radio-button>
            <el-radio-button label="false">NO</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- eslint-disable -->
    <el-table
      :data="siteApi"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      lazy
      :key="tableKey"
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column prop="name" label="Name"/>
      <el-table-column prop="code" label="Code"/>
      <el-table-column prop="needLogin" label="NeedLogin" align="left">
        <template #default="scope">
          <el-tag v-if="scope.row.needLogin" type="success">
            {{ scope.row.needLogin }}
          </el-tag>
          <el-tag v-if="!scope.row.needLogin" type="warings">
            {{ scope.row.needLogin }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column type="title" label="Action">
        <template #default="scope">
          <el-button
            v-if="!checkBtnId(scope.row.id)"
            icon="el-icon-edit"
            size="mini"
            type="success"
            @click="showEdit(scope.row)"
            v-permission="['sys:api:update']"
          />
          <el-button
            v-if="!checkBtnId(scope.row.id)"
            icon="el-icon-remove"
            size="mini"
            type="danger"
            @click="removeSiteAPi(scope.row)"
            v-permission="['sys:api:delete']"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { computed, nextTick, onMounted } from '@vue/runtime-core'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  createSiteApi,
  deleteSiteApi,
  getSiteApi,
  updateSiteApi,
} from '../../../api/site-api'
import { required } from '../../../utils/validate'
import { store } from '../../../store'
import { hasRole } from '../../../utils/util'
import { cellGrayWhite } from '@/utils/style'

const builtInApiCode = ref([
  {
    value: 'fetch_user_info',
    label: 'fetch_user_info'
  },
  {
    value: 'login_with_password',
    label: 'login_with_password'
  },
  {
    value: 'offline_msg_notification',
    label: 'offline_msg_notification'
  }
]);

const siteId = computed(() => {
  return store.state.user.siteId
})

const userSites = computed(() => {
  return store.state.user.sites
})

const formRef = ref(null)
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogLoading: false,
  searchDialogVisible: false,
})

const tableKey = ref(Math.random())

const form = reactive({
  id: null,
  name: null,
  code: null,
  url: null,
  bodyJson: null,
  siteId: null,
  status: true,
  apiId: null,
  needLogin: false,
})

const siteApi = ref([])

const formRules = reactive({
  name: [required('name is required')],
  code: [required('code is required')],
  url: [required('url is required')],
  bodyJson: [required('bodyjson is required')],
  siteId: [required('site is required')],
  showButton: [required('show Button is required')],
})

const request = reactive({
  size: 30,
  current: 1,
  siteId: null,
})

function showDialog(type) {
  if (formRef.value) {
    formRef.value.resetFields()
  }
  if (type === 'CREATE') {
    uiControl.dialogTitle = 'Add Site Api'
    form.id = null
    form.siteId = siteId.value
    form.needLogin = false
  } else if (type === 'EDIT') {
    uiControl.dialogTitle = 'Edit Site Api'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function showEdit(formdata) {
  showDialog('EDIT')
  nextTick(() => {
    for (const key in formdata) {
      if (Object.keys(form).find(k => k === key)) {
        form[key] = formdata[key]
      }
    }
  })
}

function checkBtnId(id) {
  var rowId = id + ''
  if (rowId.includes('Btn')) {
    return true
  } else {
    return false
  }
}

function submit() {
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else if (uiControl.dialogType === 'EDIT') {
    edit()
  }
}

function create() {
  formRef.value.validate(async valid => {
    if (valid) {
      await createSiteApi(form)
      uiControl.dialogVisible = false
      refresh()
      ElMessage({ message: 'Add Success', type: 'success' })
    }
  })
}

function edit() {
  formRef.value.validate(async valid => {
    if (valid) {
      await updateSiteApi(form)
      uiControl.dialogVisible = false
      refresh()
      ElMessage({ message: 'Edit Success', type: 'success' })
    }
  })
}

async function removeSiteAPi(message) {
  ElMessageBox.confirm(
    'Confirm that you want to delete Api, the operation cannot be undone',
    {
      confirmButtonText: 'confirm',
      cancelButtonText: 'cancel',
      type: 'warning',
    }
  ).then(async () => {
    await deleteSiteApi([message.id])

    refresh()
    ElMessage({ message: 'Delete Success', type: 'success' })
  })
}

async function load(tree, treeNode, resolve) {
  // debugger
  let arr = []
  arr = Object.values(tree.siteApiButtonVOList)
  arr.forEach(x => (x.id = x.id + 'Btn'))
  resolve(arr)
}

async function loadSiteApi() {
  const { data: ret } = await getSiteApi(request)
  siteApi.value = ret
}

function refresh() {
  loadSiteApi()
  tableKey.value = Math.random()
}

onMounted(async () => {
  await loadSiteApi()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
