<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-date-picker
          v-model="startDate"
          type="date"
          placeholder="Start Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-date-picker
          style="margin-left: 20px"
          v-model="endDate"
          type="date"
          placeholder="End Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="getRatingStatistics()"
        >
          Search
        </el-button>
        <el-button style="margin-left: 20px"
                   icon="el-icon-download"
                   size="mini"
                   type="success"
                   @click="download()"
        />
      </div>
    </div>
    <el-row>
      <div class="summary" v-if="list.records.length > 0">
        <h4>Rating overview ({{ totalCount }})</h4>
        <div style="display:flex;align-items: center;justify-content: space-between;">
          <star-rating v-model:rating="rating" :increment="0.1" :read-only="true" :rounded-corners="true" :padding="10" star-size="30" inline="true" :show-rating="false" />
          <span style="display:flex;align-items: center;">
            <star-rating rating="1" max-rating="1" :read-only="true" :rounded-corners="true" :padding="10" star-size="30" inline="true" :show-rating="false" style="display:inline-block;margin-right:10px;" />
            <h4 style="display:inline-block;margin-top:0px;margin-bottom:0px;">{{ rating }}/5</h4>
          </span>
        </div>
      </div>
    </el-row>
    <el-row>
      <el-table
        class="summary"
        :data="list.records"
        ref="table"
        row-key="id"
        size="small"
        lazy
        highlight-current-row
        :cell-class-name="cellWrapUpGrayWhite"
      >
        <el-table-column prop="rating" label="Rating">
          <template #default="scope">
            <star-rating v-model:rating="scope.row.rating" :read-only="true" :rounded-corners="true" :padding="5" star-size="20" inline="true" :show-rating="false" style="margin: 5px 0px 0px;" />
          </template>
        </el-table-column>
        <el-table-column prop="count" label="Count" />
        <el-table-column prop="percentage" label="Percentage">
          <template #default="scope">
            <span>{{ scope.row.percentage }} %</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div style="margin-top:50px">
      <h4>Rating By User</h4>
      <el-table
        :data="list.userRatingRecords"
        ref="table"
        row-key="identifier"
        size="small"
        lazy
        highlight-current-row
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :cell-class-name="cellWrapUpGrayWhite"
      >
        <el-table-column prop="loginName" label="Login Name" />
        <el-table-column prop="rating" label="Rating">
          <template #default="scope">
            <star-rating v-model:rating="scope.row.rating" :rounded-corners="true" :read-only="true" :increment="0.1" :padding="5" star-size="20" inline="true" style="margin: 5px 0px 0px;" />
          </template>
        </el-table-column>
        <el-table-column prop="count" label="Rating Count" />
        <el-table-column prop="percentage" label="Count Percentage">
          <template #default="scope">
            <span>{{ scope.row.percentage }} %</span>
          </template>
        </el-table-column>
        <el-table-column prop="loginName" label="Comments" width="150px">
          <template #default="scope">
            <div
              v-if="scope.row.loginName"
              style="text-decoration: underline;cursor: pointer;"
              @click="showComments(scope.row.loginName)"
            >
              View Comments
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-drawer
      v-model="drawer"
      title="I am the title"
      :with-header="false"
      :destroy-on-close="true"
      size="50%"
      :open-delay="300"
    >
      <roomComments :loginName="name" :startDate="startDate" :endDate="endDate" />
    </el-drawer>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import StarRating from 'vue-star-rating'
import roomComments from './comment'
import { getRatingStats, getUserRatingStats } from '../../../api/report'
import { convertDate, exportExl } from '@/utils/util'
import { cellWrapUpGrayWhite } from '@/utils/style'

const list = reactive({
  records: [],
  userRatingRecords: []
})

const drawer = ref(false)
const name = ref('')

const startDate = ref(convertDate(new Date()))
const endDate = ref(convertDate(new Date()))

const totalCount = ref(0)
const rating = ref(0)

async function download() {
  const json = [];
  list.userRatingRecords.forEach((item, index) => {
    json.push({ login_name: item.loginName, rating: item.rating, rating_count: item.count })
  });
  json.push({ count: totalCount.value });
  const fileName = `${startDate.value}-${endDate.value}.xls`;
  exportExl(json, fileName);
}

function compare(a, b) {
  if (a.rating < b.rating) {
    return 1;
  }
  if (a.rating > b.rating) {
    return -1;
  }
  return 0;
}

async function getRatingStatistics() {
  const { data: res } = await getRatingStats(startDate.value, endDate.value)
  res.sort(compare);
  list.records = res

  if (res.length > 0) {
    totalCount.value = res
      .map(stats => stats.count)
      .reduce((acc, count) => acc + count)

    rating.value = (res.map(stats => stats.rating * stats.count)
      .reduce((acc, count) => acc + count) / totalCount.value)
      .toFixed(1)
  }

  const { data: user } = await getUserRatingStats(startDate.value, endDate.value)
  list.userRatingRecords = user
  list.userRatingRecords.forEach(
    userRating => {
      let ratingSum = 0;
      if (userRating.children.length > 0) {
        userRating.children.sort(compare)
        userRating.children.forEach(
          userRatingChild => {
            ratingSum += (userRatingChild.rating * userRatingChild.count)
          }
        )
      }
      userRating.rating = (ratingSum / userRating.count).toFixed(1)
    }
  )
  list.userRatingRecords.sort(compare);
}

function showComments(loginName) {
  drawer.value = true
  name.value = loginName
}

onMounted(async () => {
  await getRatingStatistics()
})
</script>

<style scoped>
.summary {
  width: 500px;
  margin-top: 50px;
}
</style>
