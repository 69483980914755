<template>
  <div class="roles-main">
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      :inline="true"
      size="small"
    >
      <el-form-item label="Site" prop="siteId" v-if="hasRole(['ADMIN'])">
        <el-select
          v-model="form.siteId"
          size="small"
          placeholder="Site"
          style="width: 350px"
          :disabled="true"
        >
          <el-option
            v-for="item in userSites"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <div id="app">
        <!-- replace the api key -->
        <editor
          api-key="arlf5h2wcrqydmiiogww80cfx98khxd8o81s2i93qhq0n7wv"
          :init="{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'formatselect | bold italic underline backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | link | removeformat | code | undo redo |  help',
          }"
          v-model="form.content"
        />
      </div>

      <div class="form-footer">
        <el-button
          type="primary"
          @click="submit"
          v-if="type === 'Add'"
          v-permission="['sys:welcome:create']"
        >
          {{ type }}
        </el-button>
        <el-button
          type="primary"
          @click="submit"
          v-if="type === 'Edit'"
          v-permission="['sys:welcome:update']"
        >
          {{ type }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script setup>
import Editor from '@tinymce/tinymce-vue'
import { reactive, ref } from '@vue/reactivity'
import { nextTick, onMounted, computed } from '@vue/runtime-core'
import { ElMessage } from 'element-plus'
import {
  createSiteWelcome,
  getSiteWelcome,
  // getSiteWelcomeById,
  updateSiteWelcome,
} from '../../../api/site-welcome'
import { store } from '../../../store'
import { hasRole } from '../../../utils/util'
import { required } from '../../../utils/validate'

/* https://www.tiny.cloud/docs-4x/integrations/vue/
    https://www.tiny.cloud/docs/tinymce/6/vue-ref/
    https://github.com/tinymce/tinymce-vue#usage
    https://www.tiny.cloud/blog/tinymce-toolbar
 */
const editor = Editor

const formRef = ref()

const form = reactive({
  id: null,
  content: null,
  siteId: null,
})

const formRules = reactive({
  siteId: [required('siteId is required')],
})

const userSiteId = computed(() => {
  return store.state.user.siteId
})

const userSites = computed(() => {
  return store.state.user.sites
})

const type = ref(null)
function submit() {
  if (type.value === 'Add') {
    create()
  } else {
    edit()
  }
}

function create() {
  formRef.value.validate(async valid => {
    if (valid) {
      await createSiteWelcome(form)
      ElMessage({ message: 'Add Success', type: 'success' })
    }
  })
}

function edit() {
  formRef.value.validate(async valid => {
    if (valid) {
      await updateSiteWelcome(form)
      ElMessage({ message: 'Edit Success', type: 'success' })
    }
  })
}

async function loadForm() {
  const { data: ret } = await getSiteWelcome()
  if (ret === undefined || ret === null) {
    type.value = 'Add'
  } else {
    type.value = 'Edit'
    nextTick(() => {
      for (const key in ret) {
        if (Object.keys(form).find(k => k === key)) {
          form[key] = ret[key]
        }
      }
    })
  }
}

onMounted(async () => {
  form.siteId = userSiteId.value
  loadForm()
})
</script>
<style scoped>
.form-footer {
  justify-content: space-between;
  margin-top: 1em;
  float: right;
}
</style>
<style rel="stylesheet/scss">
.tox-notifications-container {
  display: none;
}
</style>
