import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getAllocations = () => {
  return https().request("/system-server/allocations", Method.GET);
};

export const updateAllocation = (data) => {
  return https().request(`/system-server/allocations?_method=PUT`, Method.POST, data, ContentType.form);
};
