import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getBlacklist = () => {
  return https().request("/site-blacklist", Method.GET);
};

export const deleteBlacklist = (ids) => {
  return https().request(`/site-blacklist?_method=DELETE`, Method.POST, { ids: ids }, ContentType.form);
};

export const addBlacklist = (form) => {
  return https().request(`/site-blacklist`, Method.POST, form, ContentType.form);
};
