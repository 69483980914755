<template>
  <div class="roles-main">
    <el-card class="card-chat">
      <template #header>
        <div class="card-header">
          <span>Transcript</span>
        </div>
      </template>
      <div style="max-height:500px; font-size:15px;overflow-y: scroll">
        <el-table
          :data="page.records"
          ref="table"
          row-key="id"
          size="small"
          lazy
          highlight-current-row
          :cell-class-name="cellWrapUpGrayWhite"
        >
          <el-table-column prop="guestRating" label="Rating" width="150">
            <template #default="scope">
              <star-rating v-model:rating="scope.row.guestRating" :rounded-corners="true" :read-only="true" :increment="0.1" :padding="5" star-size="15" inline="true" style="margin: 5px 0px 0px;" />
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="Time" width="150" />
          <el-table-column prop="guestComment" label="Comment" />
        </el-table>
      </div>
    </el-card>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { onMounted } from '@vue/runtime-core'
import { getUserComments } from '../../../../api/report'
import { defineProps, reactive } from 'vue'
import { cellWrapUpGrayWhite } from "@/utils/style";
import StarRating from "vue-star-rating";

const page = reactive({
  pages: 0,
  records: [],
})

const request = reactive({
  loginName: null,
  startDate: null,
  endDate: null,
  size: 30,
  current: 1,
})

const props = defineProps({
  loginName: {
    type: String,
    default: () => "",
  },
  startDate: {
    type: Date,
    default: () => "",
  },
  endDate: {
    type: Date,
    default: () => "",
  },
})

function changePage(page) {
  request.current = page
  loadComments()
}

async function loadComments() {
  const { data: ret } = await getUserComments(request)
  page.pages = ret.pages
  page.records = ret.records
}

onMounted(async () => {
  request.loginName = props.loginName;
  request.startDate = props.startDate;
  request.endDate = props.endDate;
  await loadComments()
})

</script>

<style>
.card-style {
  margin-bottom: 10px;
  height: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-chat {
  min-height: 350px;
  max-height: 800px;
  margin-bottom: 10px;
}

.message {
  width: 100%;
  margin-bottom: 0.75rem;
}

.message-meta {
  margin-bottom: 0.25rem;
}

.message-datetime {
  color: grey;
}

.message-image {
  max-width: 128px;
}

.comment {
  overflow: auto;
}
</style>
