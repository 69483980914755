<template>
  <div class="roles-main">
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="450px"
    >
      <el-form
        ref="allocationForm"
        :rules="formRules"
        :model="form"
        :inline="true"
        size="medium"
        label-width="150px"
      >
        <el-form-item label="Server Code" prop="serverCode">
          <el-input
            :disabled="true"
            v-model="form.serverCode"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="Sites">
          <el-select
            v-model="form.siteIds"
            multiple
            placeholder="Select"
            style="width: 240px"
          >
            <el-option
              v-for="item in availableSiteList.list"
              :key="item.id"
              :label="item.siteName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="records.list"
      ref="table"
      row-key="id"
      style="width: 100%"
      size="small"
      :span-method="onSpanMethod"
      :cell-class-name="cellClass"
      class="no-highlight"
    >
      <el-table-column prop="serverCode" label="Server Code" />
      <el-table-column label="Site" width="100">
        <template #default="scope">
          <div>{{ toSiteName(scope.row.siteId) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="totalVisitors" label="Total Visitors" />
      <el-table-column prop="loggedInAgent" label="Logged In Agents" />
      <el-table-column prop="messageCount" label="Traffic (message/min)" />
      <el-table-column prop="ip" label="Ip Address" />
      <el-table-column prop="startTime" label="Start Time" />
      <el-table-column prop="lastCheckTime" label="Last Check Time" />
      <el-table-column label="operate">
        <template #default="scope">
          <el-button
            size="mini"
            type="success"
            icon="el-icon/>"
            @click="showEditAllocation(scope.row)"
          >
            Update Allocation
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, ref, onBeforeUnmount, onBeforeMount } from 'vue'
import { ElMessage } from 'element-plus'
import { getAllocations, updateAllocation } from '../../../api/system-server'
import { required } from '../../../utils/validate'
import { getSiteListSimple } from '../../../api/site'
import { spanRow } from "element-ui-table-span-method";

let timer = ref(null);

const siteList = reactive({
  list: [],
})

const availableSiteList = reactive({
  list: [],
})

const records = reactive({
  list: [],
  uniqueIds: []
})

const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
})

const allocationForm = ref(null)

const form = reactive({
  systemServerId: null,
  serverCode: null,
  siteIds: null,
})

const formRules = reactive({
  systemServerId: [required('Server ID is required')],
})

function submit() {
  updateSystemServerAllocation()
}

function toSiteName(siteId) {
  if (!siteId) {
    return "-"
  }
  return siteList.list.find(site => site.id === Number(siteId)).siteName
}

async function loadSites() {
  const { data: ret } = await getSiteListSimple()
  siteList.list = ret
}

async function loadSystemServerAllocations() {
  const { data: ret } = await getAllocations()
  records.list = ret
  records.uniqueIds = [...new Set(records.list.map(record => record.id))];
}

function showEditAllocation(systemServer) {
  uiControl.dialogTitle = 'Update Server Site Allocation'
  uiControl.dialogVisible = true
  nextTick(() => {
    form.systemServerId = systemServer.id
    form.serverCode = systemServer.serverCode
    form.siteIds = systemServer.sites.slice();
    loadAvailableSites();
  })
}

function updateSystemServerAllocation() {
  allocationForm.value.validate(async valid => {
    if (valid) {
      await updateAllocation(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update System Server Allocation Success', type: 'success' })
    }
    loadSystemServerAllocations()
  })
}

function loadAvailableSites() {
  availableSiteList.list = [];

  const otherSites = [];
  records.list.filter(record => record.id !== form.systemServerId).forEach(record => {
    otherSites.push.apply(otherSites, record.sites.slice())
  });

  siteList.list.filter(site => !otherSites.includes(site.id)).forEach(
    site => {
      availableSiteList.list.push(site);
    }
  );
}

const option = [
  { index: 0, field: "serverCode" },
  { index: 5, field: "serverCode" },
  { index: 6, field: "serverCode" },
  { index: 7, field: "serverCode" },
  { index: 8, field: "serverCode" },
]

function onSpanMethod({ row, column, rowIndex, columnIndex }) {
  return spanRow(
    { row, column, rowIndex, columnIndex },
    records.list,
    option
  );
}

function cellClass({ row, column, rowIndex, columnIndex }) {
  var result = []
  const index = records.uniqueIds.indexOf(row.id)
  if (index % 2 === 0) {
    result.push("cell-grey")
  }
  if (columnIndex === 8) {
    result.push("allow-click")
  }
  return result.join(" ")
}

onMounted(async() => {
  await loadSites()
  await loadSystemServerAllocations()
})

onBeforeUnmount(async () => {
  clearInterval(timer);
})

onBeforeMount(async () => {
  timer = setInterval(loadSystemServerAllocations, 30000)
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 38px;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.icon-body {
  width: 100%;

  .headicon-box {
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    div {
      cursor: pointer;

    }
  }
}

.headicon-img {
  width: 60px;
  display: block;
}

:deep(.no-highlight tbody tr) {
  pointer-events: none;
}

:deep(.allow-click) {
  pointer-events: auto !important;
}

:deep(.el-radio-button--small .el-radio-button__inner) {
  padding: 0;
}
// :deep(.no-hover:hover > td) {
//   background-color: initial !important;
// }
.hover-row > td {
  background-color: initial !important;
}

:deep(.el-radio-button--small) {
  padding: 0;
  img{

    filter: grayscale(.7);
  }

  &.is-active {
    img {
      filter: grayscale(0);
    }
  }
}
</style>
