<template>
  <el-row>
    <el-col :span="20"
            v-if="hasPermission(['sys:view:dashboard:analytics'])"
    >
      <h4>RealTime</h4>
      <statisticsRealtime v-if="stats" :stats="stats" />
      <h4>Room</h4>
      <statisticsRoom :room="room" />
    </el-col>
    <el-col :span="4">
      <download />
    </el-col>
  </el-row>
</template>

<script setup>
import statisticsRealtime from '../../views/home/statistics-realtime'
import statisticsRoom from '../../views/home/statistics-room'
import download from '../../views/home/download'
import { getRealTimeStats, getRoomStats } from '../../api/statistics'
import { hasPermission } from '../../utils/util'
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from '@vue/runtime-core'
// import resize from "@/components/charts/mixins/resize";
// import { init } from "echarts";
// import moment from "moment";
const stats = ref({
  inQueue_visitors: 0,
  chatting_visitors: 0,
  total_visitors: 0,
  loggedIn_agent: 0,
  chatting_agent: 0,
})

let timer = ref(null);

const room = ref([
  {
    recordTime: null,
    totalCount: 0,
  },
])

async function getStatsRealTime() {
  const { data: statResult } = await getRealTimeStats()
  stats.value = statResult;
}

async function getStatsRoom() {
  const { data: res } = await getRoomStats()
  const statResult = res
  room.value = statResult
}

onMounted(async () => {
  await getStatsRealTime()
  await getStatsRoom()
})

onBeforeUnmount(async () => {
  clearInterval(timer);
})
onBeforeMount(async () => {
  timer = setInterval(() => {
    getStatsRealTime()
    getStatsRoom()
  }, 30000)
})
</script>
<style scoped>
/*.month-summary {*/
/*  width: 100%;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*}*/

/*.month-summary .box-card {*/
/*  padding: 0px;*/
/*  height: 100px;*/
/*  width: 300px;*/
/*  margin-bottom: 20px;*/
/*}*/

/*.month-summary .box-card i {*/
/*  font-size: 50px;*/
/*}*/

/*.month-summary .box-card div {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: space-between;*/
/*  height: 55px;*/
/*}*/

/*.month-summary .box-card div span:nth-child(1) {*/
/*  font-size: 14px;*/
/*  color: rgba(0, 0, 0, .45);*/
/*}*/

/*.month-summary .box-card div span:nth-child(2) {*/
/*  font-size: 20px;*/
/*  font-weight: 700;*/
/*}*/
</style>
