<template>
  <div style="width: 40%">
    <el-table
      :data="props.room"
      style="width: 100%"
      size="small"
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column prop="recordTime" label="Date" width="250" align="left">
        <template #default="scope">
          <span v-if="scope.row.recordTime === null">-</span>
          <!--eslint-disable -->
          <span
            v-if="
              convertDate(scope.row.recordTime) === convertDate(new Date())
            "
          >
            Today
          </span>
          <span v-if="convertDate(scope.row.recordTime) === getPreviousDay()">
            Yesterday
          </span>
          <span
            v-if="
              scope.row.recordTime !== null &&
              convertDate(scope.row.recordTime) !==
                new Date().toISOString().slice(0, 10) &&
              convertDate(scope.row.recordTime) !== getPreviousDay()
            "
            v-formatter="{
              data: scope.row.recordTime,
              formatter: 'YYYY/MM/DD',
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="totalCount"
        label="Total Count"
        width="250"
        align="left"
      />
    </el-table>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { convertDate } from '@/utils/util'
import { cellGrayWhite } from '@/utils/style'
import moment from "moment";
// class Room {
//   constructor(fetchTime, totalCount) {
//     this.fetchTime = fetchTime
//     this.totalCount = totalCount
//   }
// }

const props = defineProps({
  room: {
    type: Object,
    default: () => {
      return {
        recordTime: 0,
        totalCount: 0,
      }
    },
  },
})

// function extractDate(datestr) {
//   if (datestr !== null) {
//     const date = datestr.substr(0, datestr.indexOf('T'));
//     return date
//   }
// }

function getPreviousDay(date = new Date()) {
  return convertDate(moment(date).add(-1, 'days'));
}
</script>

<style>
</style>
