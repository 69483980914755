<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-date-picker
          v-model="startDate"
          type="date"
          placeholder="Start Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-date-picker
          style="margin-left: 20px"
          v-model="endDate"
          type="date"
          placeholder="End Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="getDeviceStatistics()"
        >
          Search
        </el-button>
        <el-button style="margin-left: 20px"
                   icon="el-icon-download"
                   size="mini"
                   type="success"
                   @click="download()"
        />
        <h6 style="float: right">Total Count : {{ totalCount }}</h6>
      </div>
    </div>
    <div class="chart" v-if="list.records.length > 0">
      <Chart :options="options" />
    </div>
    <el-table
      :data="list.records"
      ref="table"
      row-key="id"
      size="small"
      lazy
      highlight-current-row
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      :cell-class-name="cellWrapUpGrayWhite"
    >
      <el-table-column prop="device" label="Device" />
      <el-table-column prop="count" label="Count" />
      <el-table-column prop="percentage" label="Percentage">
        <template #default="scope">
          <span>{{ scope.row.percentage }} %</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import Chart from '@/components/charts/Charts'
import { getDeviceStats } from '../../../api/report'
import { convertDate, exportExl } from '@/utils/util'
import { cellWrapUpGrayWhite } from '@/utils/style'

const list = reactive({
  records: [],
})

const startDate = ref(convertDate(new Date()))
const endDate = ref(convertDate(new Date()))

const options = reactive({
  series: [],
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c} ({d}%)',
  },
  legend: {
    top: '5%',
    left: 'center',
  },
})

const totalCount = ref(0)

async function download() {
  const json = [];
  list.records.forEach((item, index) => {
    json.push({ group: item.device, group_count: item.count })
  });
  json.push({ count: totalCount.value });
  const fileName = `${startDate.value}-${endDate.value}.xls`;
  exportExl(json, fileName);
}

async function getDeviceStatistics() {
  const { data: res } = await getDeviceStats(startDate.value, endDate.value)
  list.records = res

  if (res.length > 0) {
    totalCount.value = res
      .map(stats => stats.count)
      .reduce((acc, count) => acc + count)

    const pieData = []
    res.forEach((item, index) => {
      pieData.push({ value: item.count, name: item.device })
    })
    options.series = [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: pieData,
      },
    ]
  }
}

onMounted(async () => {
  await getDeviceStatistics()
})
</script>

<style scoped>
.chart {
  width: 500px;
  height: 400px;
  margin: auto;
}
</style>
