<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-date-picker
          v-model="request.startDate"
          type="date"
          placeholder="Start Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-date-picker
          style="margin-left: 20px"
          v-model="request.endDate"
          type="date"
          placeholder="End Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-input
          v-model="request.loginName"
          size="small"
          style="margin-left: 20px;width: 200px"
          placeholder="Login Name"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadUserResponseStats()"
        >
          Search
        </el-button>
        <el-button style="margin-left: 20px"
                   icon="el-icon-download"
                   size="mini"
                   type="success"
                   @click="download()"
        />
        <h6 style="float: right">Total Count : {{ totalCount }}</h6>
      </div>
    </div>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="mini"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column prop="nickName" label="Name" />
      <el-table-column prop="avgFirstResponseSeconds" label="Avg First Response (seconds)" />
      <el-table-column prop="firstResponseTotalCount" label="Total First Responses" />
      <el-table-column prop="avgAvgResponseSeconds" label="Avg Response (seconds)" />
      <el-table-column prop="avgResponseTotalCount" label="Total Responses" />
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import { getUserResponseStats } from '../../../api/report'
import { convertDate } from '@/utils/util'
// import { convertDate, exportExl } from '@/utils/util'
import { cellGrayWhite } from '@/utils/style'

const page = reactive({
  pages: 0,
  records: [],
})

const request = reactive({
  size: 30,
  current: 1,
  loginName: null,
  startDate: convertDate(new Date()),
  endDate: convertDate(new Date()),
})

function changePage(page) {
  request.current = page
  loadUserResponseStats()
}

const totalCount = ref(0)

// async function download() {
//   const json = [];
//   list.records.forEach((item, index) => {
//     json.push({ group: item.device, group_count: item.count })
//   });
//   json.push({ count: totalCount.value });
//   const fileName = `${startDate.value}-${endDate.value}.xls`;
//   exportExl(json, fileName);
// }

async function loadUserResponseStats() {
  const { data: ret } = await getUserResponseStats(request)
  page.pages = ret.pages
  page.records = ret.records
}

onMounted(async () => {
  await loadUserResponseStats()
})
</script>

<style scoped>
.chart {
  width: 500px;
  height: 400px;
  margin: auto;
}
</style>
