<template>
  <div style="width: 80%">
    <el-row align="middle">
      <el-col :span="2">
        <el-icon color="#000">
          <Icon :icon="baselinePeople" class="stats-icon" />
        </el-icon>
      </el-col>
      <el-col :span="2">
        <h1>{{ props.stats.inQueueVisitors }}</h1>
      </el-col>
      <el-col :span="4">InQueue Visitor</el-col>

      <el-col :span="2" />

      <el-col :span="2">
        <el-icon color="#000">
          <Icon :icon="commentDiscussion" class="stats-icon" />
        </el-icon>
      </el-col>
      <el-col :span="2">
        <h1>{{ props.stats.chattingVisitors }}</h1>
      </el-col>
      <el-col :span="4">Chatting Visitor</el-col>
    </el-row>

    <el-row align="middle">
      <el-col :span="2">
        <el-icon color="#000">
          <Icon :icon="baselineOtherHouses" class="stats-icon" />
        </el-icon>
      </el-col>
      <el-col :span="2">
        <h1>{{ props.stats.totalVisitors }}</h1>
      </el-col>
      <el-col :span="4">Total Visitor</el-col>

      <el-col :span="2" />

      <el-col :span="2">
        <el-icon color="#000">
          <Icon :icon="roundSupportAgent" class="stats-icon" />
        </el-icon>
      </el-col>
      <el-col :span="2">
        <h1>{{ props.stats.loggedInAgent }}</h1>
      </el-col>
      <el-col :span="4">LoggedIn Agent</el-col>
    </el-row>
    <el-row align="middle">
      <el-col :span="2">
        <el-icon color="#000">
          <Icon :icon="feedbackIcon" class="stats-icon" />
        </el-icon>
      </el-col>
      <el-col :span="2">
        <h1>{{ props.stats.chattingAgent }}</h1>
      </el-col>
      <el-col :span="4">Chatting Agent</el-col>

      <el-col :span="2" />

      <el-col :span="2">
        <el-icon color="#000">
          <Icon :icon="hubotIcon" class="stats-icon" />
        </el-icon>
      </el-col>
      <el-col :span="2">
        <h1>{{ props.stats.robotChattingVisitors }}</h1>
      </el-col>
      <el-col :span="4">Robot Chatting Visitor</el-col>
    </el-row>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { Icon } from '@iconify/vue'
import feedbackIcon from '@iconify-icons/codicon/feedback'
import hubotIcon from '@iconify-icons/codicon/hubot'
import commentDiscussion from '@iconify-icons/codicon/comment-discussion'
import baselineOtherHouses from '@iconify-icons/ic/baseline-other-houses';
import roundSupportAgent from '@iconify-icons/ic/round-support-agent';
import baselinePeople from '@iconify-icons/ic/baseline-people';

const props = defineProps({
  stats: {
    type: Object,
    default: () => {
      return {
        inQueue_visitors: 0,
        chatting_visitors: 0,
        total_visitors: 0,
        loggedIn_agent: 0,
        chatting_agent: 0,
        robotChattingVisitors: 0,
      }
    },
  },
})
</script>

<style>
.stats-icon {
  font-size: 3em;
}
</style>
