import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getWhitelist = () => {
  return https().request("/site-ip-whitelist", Method.GET);
};

export const deleteWhitelist = (ids) => {
  return https().request(`/site-ip-whitelist?_method=DELETE`, Method.POST, { ids: ids }, ContentType.form);
};

export const addWhitelist = (form) => {
  return https().request(`/site-ip-whitelist`, Method.POST, form, ContentType.form);
};
