import '../styles/el_table.css';

export const cellWrapUpGrayWhite = ({ row, column, rowIndex, columnIndex }) => {
  if (row.parentId === -1) {
    return "cell-grey";
  } else {
    return "cell-green";
  }
}

export const cellGrayWhite = ({ row, column, rowIndex, columnIndex }) => {
  if (rowIndex % 2 === 0) {
    return "cell-grey";
  } else {
    return "";
  }
}

export const cellGrayWhiteOnline = ({ row, column, rowIndex, columnIndex }) => {
  let className;
  if (column.label === 'Status') {
    if (row.status === 'ONLINE') {
      className += " cell-green";
    } else if (row.status === 'AWAY') {
      className += " cell-goldenrod";
    } else {
      className += " cell-red";
    }
  }
  if (rowIndex % 2 === 0) {
    className += " cell-grey";
  }
  return className;
}

export const cellGrayRoom = ({ row, column, rowIndex, columnIndex }) => {
  let className;
  if (column.label === 'Status') {
    if (row.status === 'FINISHED' || row.status === 'CLOSED') {
      className += " cell-red";
    } else if (row.status === 'MATCHED' || row.status === 'ROBOT') {
      className += " cell-green";
    } else {
      className += " cell-goldenrod";
    }
  }
  if (rowIndex % 2 === 0) {
    className += " cell-grey";
  }
  return className;
}
