<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-date-picker
          v-model="startDate"
          type="datetime"
          placeholder="Start Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD HH:mm:ss"
          size="small"
        />
        <el-date-picker
          style="margin-left: 20px"
          v-model="endDate"
          type="datetime"
          placeholder="End Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD HH:mm:ss"
          size="small"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="getRoomStatistics()"
        >
          Search
        </el-button>
        <h6 style="float: right">Total Rooms : {{ totalCount }}</h6>
      </div>
    </div>
    <div class="chart-user" v-if="list.records.length > 0" id="chart-user">
      <Chart :options="options" :chartSize="{height: 400}" />
    </div>
    <el-table
      :data="list.records"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column prop="nickName" label="User" />
      <el-table-column prop="totalCount" label="Rooms" />
      <el-table-column label="Avg. Wait Time">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.avgWaitMatchTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Avg. Chat Time">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.avgFinishTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import { getRoomsStatisticsByUser } from '../../../../api/report'
import { showDateTime, convertDateTime } from '@/utils/util'
import Chart from '@/components/charts/Charts'
import 'echarts/lib/component/tooltip'
import { cellGrayWhite } from '@/utils/style'

const list = reactive({
  records: [],
})

const startDate = ref(convertDateTime(new Date().setHours(0, 0, 0, 0)))
const endDate = ref(convertDateTime(new Date().setHours(23, 59, 59, 999)))
const totalCount = ref(0)

const options = reactive({
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [100, 101],
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)'
      }
    }
  ]
})

async function getRoomStatistics() {
  const { data: res } = await getRoomsStatisticsByUser(
    startDate.value,
    endDate.value
  )
  list.records = res
  const totalCountDatas = []
  const names = []
  if (res.length > 0) {
    totalCount.value = res
      .map(stats => stats.totalCount)
      .reduce((acc, count) => acc + count)
    res.forEach((item, index) => {
      names.push(item.nickName)
      totalCountDatas.push(item.totalCount)
    })
    // debugger;
    options.xAxis.data = names;
    options.series[0].data = totalCountDatas;
  }
}

onMounted(async () => {
  await getRoomStatistics()
})
</script>

<style scoped>
.chart-user {
  /*width: 700px;*/
  height: 400px;
  margin: auto;
}
</style>
