<template>
  <el-select v-model="label" v-bind="$attrs" ref="select">
    <el-option value="">
      <el-tree
        ref="tree"
        lazy
        :expand-on-click-node="false"
        :props="treeProps"
        :load="loadTreeMenu"
        highlight-current
        @node-click="handleNodeClick"
      />
    </el-option>
  </el-select>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue'
import {
  fetchSimpleQuestion,
  fetchQuestionName,
} from '../../../../api/site-question'

const select = ref('')
const label = ref('')
const emit = defineEmits(['selected'])
const props = defineProps({
  treeProps: {
    type: Object,
    default: () => {
      return {
        id: 'id',
        label: 'name',
        children: 'children',
        isLeaf: 'isLeaf',
      }
    },
  },
  siteId: {
    type: Number,
    default: () => 0,
  },
  viewVal: {
    type: Number,
    default: () => 0,
  },
})

const defaultElement = {
  id: null,
  parentId: -1,
  siteId: props.siteId,
  name: '-----Default Option-----',
  children: [],
}

onMounted(async () => {
  updateParentName()
})

watch(
  () => props.viewVal,
  async () => {
    updateParentName()
  }
)

async function updateParentName() {
  if (props.viewVal > 0) {
    const { data: menu } = await fetchQuestionName(props.viewVal)
    label.value = menu
  } else {
    label.value = defaultElement.name
  }
}

function handleNodeClick(node) {
  label.value = node[props.treeProps.label]
  emit('selected', node[props.treeProps.id])
  select.value.blur()
}

async function loadTreeMenu(treeNode, resolve) {
  if (treeNode.level === 0) {
    const { data: children } = await fetchSimpleQuestion(-1, props.siteId)
    children.reverse().push(defaultElement)
    resolve(children.reverse())
  } else {
    if (treeNode.data.id !== null) {
      const { data: children } = await fetchSimpleQuestion(
        treeNode.data.id,
        props.siteId
      )
      resolve(children)
    } else {
      resolve([])
    }
  }
}
</script>
<style scoped>
.el-select-dropdown__item {
  height: 100%;
  padding: 10px 0;
}
</style>
